(function () {
  'use strict';

  /* @ngdoc object
   * @name ajuntament.informacio
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.ajuntament.informacio', [
    'ui.router',
    'ngMaterial',
    'neo.home.ajuntaments.ajuntament.informacio.image',
    'neo.home.ajuntaments.ajuntament.informacio.people'
  ]);
}());
